const CLASS_SHOW = 'show-nav';

const menu = () => {
  const menuTrigger = document.querySelector('.js-menu-trigger');
  const menuHeader = document.querySelector('.js-menu-header');

  menuTrigger.addEventListener('click', () => {
    menuHeader.classList.toggle(CLASS_SHOW);
  });
  window.addEventListener('resize', () => {
    if (window.matchMedia('(min-width: 1024px)')) {
      menuHeader.classList.remove(CLASS_SHOW);
    }
  });
};

export default menu;
