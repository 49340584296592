import Splide from '@splidejs/splide';

const initCarousel = () => {
  const carouselWrapper = Array.from(document.querySelectorAll('.js-carousel'));

  if (!carouselWrapper.length) return;

  carouselWrapper.forEach((carouselItem) => {
    const { carouselType, carouselItemsCount } = carouselItem.dataset;
    let carousel;

    if (carouselType === 'testimonials') {
      carousel = new Splide(carouselItem, {
        type: 'loop',
        perPage: 3,
        pagination: false,
        focus: 'center',
        padding: '-4rem',
        updateOnMove: true,
        breakpoints: {
          740: {
            perPage: 1,
          },
        },
      });
    } else {
      carousel = new Splide(carouselItem, {
        type: 'slide',
        perPage: 3,
        pagination: false,
        focus: 0,
        gap: '3rem',
        updateOnMove: true,
        arrows: +carouselItemsCount > 3,
        breakpoints: {
          740: {
            perPage: 1,
            arrows: true,
          },
        },
      });
    }

    document.addEventListener('DOMContentLoaded', function () {
      carousel.mount();
    });
  });
};

export default initCarousel;
