function ceiling(number, significance) {
  return Math.ceil(number / significance) * significance;
}
class Calculator {
  constructor() {
    this.form = document.querySelector('.js-calculator__form');

    if (!this.form) return;
    this.init();
  }

  init() {
    this.tiers = Array.from(document.querySelectorAll('.js-tier'));
    this.tiersStartingValues = this.tiers.map(
      (tier) => tier.dataset.startPrice,
    );
    this.pricingSlider = document.querySelector('.js-pricing-slider');
    this.quantityItems = Array.from(document.querySelectorAll('.js-quantity'));
    this.currentPrice = this.tiers[0].dataset.startPrice / 10000;
    this.frequencyBtns = Array.from(
      document.querySelectorAll('.js-tier-frequency-btn'),
    );
    Array.from(this.tiers).forEach((tier) => {
      let initializedActive = false;
      tier.querySelectorAll('.js-tier-frequency-btn').forEach((tfb) => {
        if (tfb.classList.contains('force-hide')) return;
        if (initializedActive) return;
        tfb.classList.add('active');
        initializedActive = true;
      });
    });
    this.pricingOption = document.querySelector(
      '.js-calculator-pricing-option-input',
    );
    this.annualRevenue = document.querySelector(
      '.js-calculator-pricing-annual-revenue',
    );
    this.updatePriceValue();
    this.getSliderInput().value = this.tiers[0].dataset.startPrice / 10000;
    this.pricingOption.value = this.tiers[0].querySelector(
      '.js-tier-frequency-btn.active',
    ).dataset.pricingOption;
    this.setEventListeners();
    this.form
      .querySelector('.js-calculator-submit')
      .addEventListener('click', () => {
        const data = new URLSearchParams(new FormData(this.form).entries());
        const dataToString = data.toString().split('&').join(',');
        window.location = `${this.form.action}?calc_website=${dataToString}`;
      });
  }

  setEventListeners() {
    this.getSliderInput().addEventListener('input', (ev) => {
      this.onPriceUpdate(ev);
    });
    this.form.addEventListener('submit', (ev) => {
      ev.preventDefault();
    });
    this.quantityItems.forEach((qItem) => {
      const qi = qItem;
      qi.querySelector('.js-quantity-input').value =
        qi.querySelector('.js-quantity-input').min;
      qi.querySelector('.js-quantity-input').addEventListener('input', () => {
        this.updateTierPricing();
      });
      qi.querySelector('.js-quantity-decrease').addEventListener(
        'click',
        () => {
          qi.querySelector('.js-quantity-input').value =
            +qi.querySelector('.js-quantity-input').value - 1;
          this.updateTierPricing();
        },
      );
      qi.querySelector('.js-quantity-increase').addEventListener(
        'click',
        () => {
          qi.querySelector('.js-quantity-input').value =
            +qi.querySelector('.js-quantity-input').value + 1;
          this.updateTierPricing();
        },
      );
    });
    this.frequencyBtns.forEach((fb) => {
      fb.addEventListener('click', (ev) => {
        if (ev.target.classList.contains('active')) return;
        Array.from(
          fb.parentElement.querySelectorAll('.js-tier-frequency-btn'),
        ).forEach((fbc) => {
          fbc.classList.remove('active');
        });
        fb.classList.add('active');
        this.pricingOption.value = fb.dataset.pricingOption;
      });
    });
  }

  onPriceUpdate(ev) {
    this.currentPrice = ev.target.value;
    this.updatePriceValue();
    this.updateTier();
    this.updateTierPricing();
  }

  updateTier() {
    this.tiersStartingValues.forEach((tier, i) => {
      let hasClosestTier = false;

      if (+this.currentPrice >= +tier / 10000) {
        if (hasClosestTier) return;
        this.tiers.forEach((tierNode) => {
          tierNode.classList.remove('show');
        });
        this.tiers[i].classList.add('show');
        hasClosestTier = true;
        this.pricingOption.value = this.tiers[i].querySelector(
          '.js-tier-frequency-btn.active',
        ).dataset.pricingOption;
      }
    });
  }

  updateTierPricing() {
    const tierPrices = Array.from(
      this.form.querySelectorAll('.js-calculator-tier-price'),
    );
    tierPrices.forEach((tp) => {
      const tpNode = tp;
      const { price, nextPrice, startPrice, range } = tp.dataset;
      const currentValue =
        this.currentPrice >= this.tiers[0].dataset.startPrice / 10000
          ? this.translateValue()
          : parseInt(this.tiers[0].dataset.startPrice, 10);
      const calcPrice = Math.ceil(
        ((currentValue - parseInt(startPrice, 10)) / parseInt(range, 10)) *
          parseInt(nextPrice, 10) +
          (1 -
            (currentValue - parseInt(startPrice, 10)) / parseInt(range, 10)) *
            parseInt(price, 10),
      );
      let currPrice = ceiling(calcPrice, 5);
      this.quantityItems.forEach((qi) => {
        const { itemPrice, itemFreeQty } = qi.dataset;
        const qty = qi.querySelector('.js-quantity-input').value;
        const qtyWithFreeItems = parseInt(qty, 10) - parseInt(itemFreeQty, 10);

        if (qtyWithFreeItems < 0) return;
        currPrice += parseInt(itemPrice, 10) * qtyWithFreeItems;
      });

      tpNode.textContent = currPrice;
    });
  }

  updatePriceValue() {
    this.pricingSlider.querySelector('.js-slider-value').innerHTML =
      this.translateValue();
    this.annualRevenue.value = this.translateValue();
  }

  translateValue() {
    return this.currentPrice * 10000;
  }

  getSliderInput() {
    return this.pricingSlider.querySelector('.js-slider-input');
  }
}

export default () => new Calculator();
