class Animation {
  constructor(selectors) {
    this.selectors = selectors;
    this.io = null;
    this.inQueue = 0;

    this.init();
  }

  animate(target) {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        target.classList.add(`o-animate--${target.dataset.animate}`);
        this.inQueue -= 1;
      });
    }, this.inQueue * 200);
  }

  destroy(target) {
    this.selector.classList.remove(`o-animate--${target.dataset.animate}`);
  }

  initObserver() {
    const options = {
      rootMargin: '10px',
      threshold: 0.01,
    };
    this.io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.inQueue += 1;
          this.animate(entry.target);
          this.io.unobserve(entry.target);
        }
      });
    }, options);
  }

  init() {
    this.initObserver();
    this.selectors.forEach((selector) => {
      selector.classList.add('o-animate--initialized');
      this.io.observe(selector);
    });
  }
}

export default () => {
  const selectors = Array.from(document.querySelectorAll('[data-animate]'));

  if (!selectors.length) return;

  const animation = () => new Animation(selectors);
  animation();
};
