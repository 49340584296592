const initFaq = () => {
  const items = Array.from(document.querySelectorAll('.js-faq-item'));

  items.forEach((item) => {
    item
      .querySelector('.b-faq__item-trigger')
      .addEventListener('click', ({ currentTarget }) => {
        const itemContent = item.querySelector('.b-faq__item-content');
        const isHidden = itemContent.getAttribute('aria-hidden') === 'true';

        itemContent.setAttribute('aria-hidden', !isHidden);
        currentTarget.setAttribute('aria-expanded', isHidden);
        currentTarget.setAttribute('aria-selected', isHidden);
      });
  });
};

export default initFaq;
