/*
  Project: Carter Cook
  Author: GoodieWebsite.com
 */

import menu from './modules/menu';
import animation from './modules/animation';
import carousel from './modules/carousel';
import faq from './modules/faq';
import calculator from './modules/calculator';
import 'lazysizes';
import 'range-slider-element';

menu();
animation();
carousel();
faq();
calculator();
